const navbar = document.querySelector('#navbar');

if (navbar) {
    ['DOMContentLoaded','scroll'].forEach( evt => 
        addEventListener(evt, addNavbarStyles, false)
    );

    function addNavbarStyles() {
        const style = "background-color: rgba(255, 255, 255, 0.85);"; 

        if (window.scrollY > 0) {
            navbar.setAttribute('style', style);
        } else {
            navbar.removeAttribute('style');
        }
    }
}
// when page loads, if there are one or more empty paragraphs like <p></p> after or before a <figure> tag, remove them
document.addEventListener('DOMContentLoaded', function () {
    const figures = document.querySelectorAll('figure');
    if (figures) {
        figures.forEach(function (figure) {
            const previous = figure.previousElementSibling;
            const next = figure.nextElementSibling;
            if (previous && previous.tagName === 'P' && previous.innerHTML === '') {
                previous.remove();
            }
            if (next && next.tagName === 'P' && next.innerHTML === '') {
                next.remove();
            }
        });
    }
});

// for <figure> tags with class 'image image_resized' or with attribute [data-trix-attachment], add margin: auto to center them
document.addEventListener('DOMContentLoaded', function () {
    const figures = document.querySelectorAll('figure.image_resized, figure[data-trix-attachment]');
    if (figures) {
        figures.forEach(function (figure) {
            figure.style.margin = 'auto';
        });
    }
});
import AOS from 'aos';

document.addEventListener('DOMContentLoaded', () => {
    AOS.init({
        once: false, // whether animation should happen only once - while scrolling down
        mirror: true, // whether elements should animate out while scrolling past them
    });
    window.AOS = AOS;
});

// Function to get the current body height
function getBodyHeight() {
    return document.body.offsetHeight;
}

// Variable to store the previous body height
let previousHeight = getBodyHeight();

// Create a ResizeObserver instance
const resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
        if (entry.target === document.body) {
            const currentHeight = getBodyHeight();
            if (currentHeight !== previousHeight) {
                // Body height has changed, refresh AOS
                if (window.AOS) {
                    window.AOS.refresh();
                }
                previousHeight = currentHeight;
            }
        }
    }
});

// Start observing the body
resizeObserver.observe(document.body);

// Clean up function (call this when you want to stop observing)
function stopObserving() {
    resizeObserver.disconnect();
}

// If you need to manually trigger a refresh, you can call this function
function manualRefresh() {
    if (window.AOS) {
        window.AOS.refresh();
        console.log('AOS manually refreshed');
    }
}
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

var swiper = new Swiper(".mySwiper", {
    modules: [Navigation, Pagination],
    loop: true,
    spaceBetween: 30,
    effect: "fade",
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });